import * as React from 'react'
import {
  Title,
  Paper,
  Grid,
  ScrollArea,
  Select,
  SegmentedControl,
  Center,
  ThemeIcon,
  Container,
  Table
} from '@mantine/core'
import { useRecoilValue } from 'recoil'
import { useTable } from '@pankod/refine-core'
import { CircleMinus, ThumbUp, Flame, Bike, Checks } from 'tabler-icons-react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')

import type { Order } from '../../interfaces'
import { activeStoreState, allStoresState } from '../../state/store'
import SellCard from '../../components/SellCard'
import ShowSell from './Show'
import { formatNumber } from '../../services/format'

const filterOptions = [
  { value: '1', label: 'última hora' },
  { value: '2', label: 'últimas 2 horas' },
  { value: '6', label: 'últimas 6 horas' },
  { value: '12', label: 'últimas 12 horas' },
  { value: '24', label: 'últimas 24 horas' }
]

const segmentOptions = [
  { value: 'all', label: <Center mx="xs">Todos os Pedidos</Center> },
  {
    value: 'CREATED',
    label: (
      <Center mx="xs">
        <ThemeIcon size="sm" variant="light" color="yellow" style={{ marginRight: 6 }}>
          <CircleMinus />
        </ThemeIcon>
        Novo
      </Center>
    )
  },
  {
    value: 'IN_PRODUCTION',
    label: (
      <Center mx="xs">
        <ThemeIcon size="sm" variant="light" color="red" style={{ marginRight: 6 }}>
          <Flame />
        </ThemeIcon>
        Em Produção
      </Center>
    )
  },
  {
    value: 'READY',
    label: (
      <Center mx="xs">
        <ThemeIcon size="sm" variant="light" color="violet" style={{ marginRight: 6 }}>
          <ThumbUp />
        </ThemeIcon>
        Pronto
      </Center>
    )
  },
  {
    value: 'IN_DELIVERY',
    label: (
      <Center mx="xs">
        <ThemeIcon size="sm" variant="light" color="blue" style={{ marginRight: 6 }}>
          <Bike />
        </ThemeIcon>
        Em Entrega
      </Center>
    )
  },
  {
    value: 'DELIVERED',
    label: (
      <Center mx="xs">
        <ThemeIcon size="sm" variant="light" color="green" style={{ marginRight: 6 }}>
          <Checks />
        </ThemeIcon>
        Concluído
      </Center>
    )
  }
]

const SellList = () => {
  const [filterStatus, setFilterStatus] = React.useState<string | undefined>(undefined)
  const [selected, setSelected] = React.useState<string | undefined>()
  const [hour, setHours] = React.useState<string>('1')
  const store = useRecoilValue(activeStoreState)
  const storeList = useRecoilValue(allStoresState)

  const { tableQueryResult } = useTable<Order>({
    resource: 'order',
    permanentFilter: [
      { field: 'lastHours', operator: 'eq', value: hour },
      { field: 'status', operator: 'eq', value: filterStatus }
    ],
    metaData: { store },
    queryOptions: {
      refetchInterval: 30000
    }
  })

  if (!tableQueryResult) {
    return null
  }

  if (storeList[store || 0]?.store.orderListType === 'simpleList') {
    return (
      <Container size="md" px="xs">
        <Paper shadow="sm" p="md" withBorder>
          <Grid justify="space-between" align="center" pl="md" pr="md" mb="lg">
            <Grid.Col span={2}>
              <Title order={3}>Pedidos</Title>
            </Grid.Col>
            <Grid.Col span={4}></Grid.Col>
            <Grid.Col span={2}>
              <span>Filtrar por</span>
            </Grid.Col>
            <Grid.Col span={4}>
              <Select data={filterOptions} value={hour} onChange={(vl) => setHours(vl ?? '1')} />
            </Grid.Col>
          </Grid>

          <ScrollArea style={{ height: '72vh' }}>
            {tableQueryResult.isLoading && (
              <>
                <SellCard />
                <SellCard />
                <SellCard />
                <SellCard />
              </>
            )}

            {tableQueryResult.status === 'success' && (
              <Table>
                <thead>
                  <tr>
                    <th>Pedido</th>
                    <th>Criado em</th>
                    <th style={{ textAlign: 'center' }}>Status</th>
                    <th>Status do Pagamento</th>
                    <th>Total</th>
                    <th>Forma de Pagamento</th>
                    <th>Origem</th>
                    <th>Cliente</th>
                  </tr>
                </thead>
                <tbody>
                  {tableQueryResult.data?.data.map((item, rowIdx) => (
                    <tr key={`row-${rowIdx}-${item.sequence}`}>
                      <td>{item.sequence}</td>
                      <td>{dayjs(item.createdAt).format('DD/MM HH:mm')}</td>
                      <td>
                        {segmentOptions.find((s) => s.value === item.status)?.label || item.status}
                      </td>
                      <td>{item.paymentStatus === 'PAID' ? 'Pago' : 'Não Pago'}</td>
                      <td>{formatNumber(item.amount / 100)}</td>
                      <td>{item.payment}</td>
                      <td>{item.source}</td>
                      <td>{item.customerName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {tableQueryResult.data?.data.length === 0 && tableQueryResult.isFetched && (
              <Center style={{ height: '60vh' }}>
                <p>Nenhum registro encontrado</p>
              </Center>
            )}
          </ScrollArea>
        </Paper>
      </Container>
    )
  }

  return (
    <>
      <Paper shadow="sm" pt="md" withBorder>
        <Grid justify="space-between" align="center" pl="md" pr="md">
          <Grid.Col span={2}>
            <Title order={3}>Pedidos</Title>
          </Grid.Col>
          <Grid.Col span={7}>
            <SegmentedControl
              size="xs"
              data={segmentOptions}
              value={filterStatus}
              onChange={setFilterStatus}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <span>Filtrar por</span>
          </Grid.Col>
          <Grid.Col span={2}>
            <Select data={filterOptions} value={hour} onChange={(vl) => setHours(vl ?? '1')} />
          </Grid.Col>
        </Grid>

        <Grid justify="space-between" align="stretch" style={{ marginTop: '2vh' }}>
          <Grid.Col span={3}>
            <ScrollArea style={{ height: '72vh', borderRight: '1px dashed #ccc' }}>
              {tableQueryResult.isLoading && (
                <>
                  <SellCard />
                  <SellCard />
                  <SellCard />
                  <SellCard />
                </>
              )}

              {tableQueryResult.data?.data.map((item) => (
                <SellCard
                  selected={item.uuid === selected}
                  key={item.uuid}
                  order={item}
                  onClick={() => setSelected(item.uuid)}
                />
              ))}

              {tableQueryResult.data?.data.length === 0 && tableQueryResult.isFetched && (
                <Center style={{ height: '60vh' }}>
                  <p>Nenhum registro encontrado</p>
                </Center>
              )}
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={9}>
            <ShowSell id={selected} onClose={() => setSelected(undefined)} />
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  )
}

export default SellList

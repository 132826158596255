import * as React from 'react'
import { createStyles, Title, Text, Button, Container, Group } from '@mantine/core'
import { useNavigation } from '@pankod/refine-core'

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.colors.gray[0],
    boxSizing: 'border-box',
    height: '100%'
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120
    }
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,
    marginTop: 0,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32
    }
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5
  }
}))

function ErrorPage() {
  const { classes } = useStyles()
  const { push } = useNavigation()

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.label}>403</div>
        <Title className={classes.title}>Ops! Algo inesperado aconteceu...</Title>
        <Text size="lg" align="center" className={classes.description}>
          Provavelmente seu usuário não tem acesso a esta página. Caso não seja este o caso, entre
          em contato com o suporte para que eles possam atualizar as suas permissões.
        </Text>
        <Group position="center">
          <Button variant="gradient" size="md" onClick={() => push('/')}>
            Voltar para home
          </Button>
        </Group>
      </Container>
    </div>
  )
}

export default ErrorPage

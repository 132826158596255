import * as React from 'react'
import { useForm } from '@pankod/refine-core'
import { useForm as useValidateForm } from '@mantine/form'
import { Drawer, ColorInput, TextInput, Group, Button, Box } from '@mantine/core'

type EditCategoryProps = {
  id: string | number
  title: string
  opened: boolean
  onClose: () => void
}

const EditCategory = ({ id, title, opened, onClose }: EditCategoryProps) => {
  const { setId, onFinish, queryResult, formLoading } = useForm({
    action: 'edit',
    redirect: false,
    resource: 'categories',
    onMutationSuccess: () => {
      onClose()
    }
  })

  const form = useValidateForm({
    initialValues: {
      title: '',
      productsColor: ''
    },

    validate: {
      title: (value) => (value ? null : 'Campo Obrigatório'),
      productsColor: (value) => (value ? null : 'Campo Obrigatório')
    }
  })

  React.useEffect(() => {
    setId(id || undefined)
  }, [id])

  React.useEffect(() => {
    form.setValues({
      title: queryResult?.data?.data.title || '',
      productsColor: queryResult?.data?.data.productsColor || ''
    })
  }, [id, queryResult?.data])

  return (
    <Drawer opened={opened} onClose={onClose} title={title} padding="md" size="lg" position="right">
      <Box sx={{ maxWidth: 300 }} mx="auto">
        <form onSubmit={form.onSubmit((values) => onFinish(values))}>
          <TextInput
            label="Titulo"
            placeholder="Titulo da categoria"
            required
            {...form.getInputProps('title')}
          />

          <ColorInput
            mt="sm"
            label="Cor"
            placeholder="Cor da categoria"
            required
            {...form.getInputProps('productsColor')}
          />

          <Group position="right" mt="lg">
            <Button type="submit" loading={formLoading}>
              Salvar Categoria
            </Button>
          </Group>
        </form>
      </Box>
    </Drawer>
  )
}

export default EditCategory

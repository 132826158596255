import * as React from 'react'
import { Select } from '@mantine/core'
import { useRecoilState } from 'recoil'

import { allStoresState, activeStoreState } from '../state/store'

const StoreSelect = (props: any) => {
  const [allStores, setAllStores] = useRecoilState(allStoresState)
  const [activeStore, setActiveStore] = useRecoilState(activeStoreState)

  React.useEffect(() => {
    const savedStores = JSON.parse(localStorage.getItem('glow-stores') || '')
    setAllStores(savedStores)
  }, [])

  const handleChange = React.useCallback(
    (val) => {
      const store = allStores[val]
      localStorage.setItem('glow-token', JSON.stringify(store.token))
      setActiveStore(parseInt(val || '0', 10))
    },
    [allStores, setActiveStore]
  )

  return (
    <Select
      {...props}
      onChange={handleChange}
      value={activeStore?.toString()}
      data={allStores.map((store, index) => ({
        value: index.toString(),
        label: store.store.title
      }))}
    />
  )
}

export default StoreSelect

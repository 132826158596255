import * as React from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useRouterContext, useList, useCustom } from '@pankod/refine-core'
import { Paper, Grid, Title, Select, Loader, Table } from '@mantine/core'
import dayjs from 'dayjs'
import isSameOfBefore from 'dayjs/plugin/isSameOrBefore'

import { availableReports } from '../../state/menu'
import { activeStoreState } from '../../state/store'

dayjs.extend(isSameOfBefore)

type ReportResponse = {
  columns: string[]
  rows: string[][]
  bottomLine: string[]
}

const buildMonths = () => {
  const output: Array<{ label: string; value: string }> = []
  const start = dayjs().subtract(12, 'month')
  const end = dayjs()

  let pivot = start
  while (pivot.isSameOrBefore(end, 'month')) {
    output.push({
      label: pivot.format('MMM/YYYY'),
      value: pivot.format('YYYYMM')
    })
    pivot = pivot.add(1, 'month')
  }

  return output
}

const ShowReport = () => {
  const selectDates = React.useMemo(buildMonths, [])
  const [selectedDate, setSelectedDate] = React.useState<string>(
    selectDates[selectDates.length - 1].value
  )

  const { useParams } = useRouterContext()
  const params = useParams<{ id: string }>()
  const [title, setTitle] = React.useState<string>('')

  const setReports = useSetRecoilState(availableReports)
  const store = useRecoilValue(activeStoreState)
  const { data } = useList({
    resource: 'reports',
    metaData: { store }
  })

  const queryResult = useCustom<ReportResponse>({
    url: `operation/report/${params.id}`,
    method: 'get',
    metaData: { id: params.id, store },
    config: {
      query: {
        monthyear: selectedDate
      }
    }
  })

  React.useEffect(() => {
    if (data) {
      const item = data.data.find((r) => r.code === params.id)

      if (item) {
        setTitle(item.title)
      }
    }
  }, [data, params])

  React.useEffect(() => {
    if (data) {
      setReports(
        data.data.map((item) => ({
          link: `${process.env.REACT_APP_SITE_BASE}reports/show/${item.code}`,
          label: item.title,
          color: 'violet'
        }))
      )
    }
  }, [data])

  return (
    <Paper shadow="sm" p="md" withBorder>
      <Grid style={{ marginBottom: 25 }} justify="space-between">
        <Grid.Col span={10}>
          <Title order={3}>{title}</Title>
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label="Selecione o período"
            data={selectDates}
            value={selectedDate}
            onChange={(v) => setSelectedDate(v || '')}
          />
        </Grid.Col>
      </Grid>

      {queryResult.status === 'loading' && <Loader />}
      {queryResult.status === 'success' && (
        <Table>
          <thead>
            <tr>
              {(queryResult.data.data.columns || []).map((title: string, idx: number) => (
                <th key={`head-${params.id}-${selectedDate}-${idx}`}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(queryResult.data.data.rows || []).map((row: string[], rowIdx: number) => (
              <tr key={`row-${rowIdx}-${selectedDate}`}>
                {(row || []).map((cell: string, cellIdx: number) => (
                  <td key={`cell-${rowIdx}-${cellIdx}-${selectedDate}`}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {(queryResult.data.data.bottomLine || []).map((title: string, idx: number) => (
                <th key={`foot-${params.id}-${selectedDate}-${idx}`}>{title}</th>
              ))}
            </tr>
          </tfoot>
        </Table>
      )}
    </Paper>
  )
}

export default ShowReport

import * as React from 'react'
import { BaseKey, useDelete } from '@pankod/refine-core'
import { Popover, Button, Text } from '@mantine/core'
import { Trash } from 'tabler-icons-react'

const DeleteButton = ({ resource, id }: { id: BaseKey; resource: string }) => {
  const [opened, setOpened] = React.useState(false)
  const { mutate } = useDelete()

  const handleDelete = () => mutate({ resource, id })

  return (
    <Popover
      opened={opened}
      position="bottom"
      placement="end"
      onClose={() => setOpened(false)}
      width={260}
      target={
        <Button compact size="xs" variant="default" onClick={() => setOpened((o) => !o)}>
          <Trash size={12} />
        </Button>
      }
    >
      <Text size="xs" style={{ marginBottom: 15 }}>
        Você tem certeza que deseja remover este item? Essa operação não pode ser desfeita
      </Text>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          compact
          size="xs"
          style={{ marginRight: 15 }}
          onClick={() => {
            handleDelete()
            setOpened(false)
          }}
        >
          Sim
        </Button>
        <Button compact size="xs" variant="default" onClick={() => setOpened(false)}>
          Não
        </Button>
      </div>
    </Popover>
  )
}

export default DeleteButton

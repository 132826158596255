import * as React from 'react'
import { Tabs } from '@mantine/core'
import { useRecoilValue } from 'recoil'
import { availableItemsState } from '../state/menu'
import useActivePill from '../services/useActivePill'
import { useNavigation, useRouterContext } from '@pankod/refine-core'

type SectionTabsProps = {
  [key: string]: unknown
}

const SectionTabs = (props: SectionTabsProps) => {
  const { useLocation } = useRouterContext()
  const { pathname } = useLocation()
  const available = useRecoilValue(availableItemsState)
  const { push } = useNavigation()
  const { index: active } = useActivePill(pathname)

  return (
    <Tabs
      variant="pills"
      grow
      active={Math.max(0, active)}
      styles={(theme) => ({
        tabActive: {
          border: `1px solid ${theme.colors.yellow[5]}`
        }
      })}
      onTabChange={(newValue) => {
        push(available[newValue].link)
      }}
      {...props}
    >
      {available.map((item) => (
        <Tabs.Tab label={item.label} key={item.label} />
      ))}
    </Tabs>
  )
}

export default SectionTabs

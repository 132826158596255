/* eslint-disable react/jsx-key */
import * as React from 'react'
import { Title, Table, Paper, Button, Grid } from '@mantine/core'
import { useTable } from '@pankod/refine-core'
import { useRecoilValue } from 'recoil'
import { Edit } from 'tabler-icons-react'
import DeleteButton from '../../components/DeleteButton'
import { activeStoreState } from '../../state/store'

import CreateCategory from './create'
import EditCategory from './edit'

const CategoryList = () => {
  const [editId, setEditId] = React.useState<string | null>(null)
  const [openCreate, setOpenCreate] = React.useState<boolean>(false)
  const store = useRecoilValue(activeStoreState)
  const { tableQueryResult } = useTable({
    resource: 'categories',
    metaData: { store }
  })

  if (!tableQueryResult || !tableQueryResult.data) {
    return null
  }

  return (
    <Paper shadow="sm" p="md" withBorder>
      <CreateCategory
        title="Criar categoria"
        opened={openCreate}
        onClose={() => setOpenCreate(false)}
      />

      <EditCategory
        title="Editar Categoria"
        id={editId || 0}
        opened={editId !== null}
        onClose={() => setEditId(null)}
      />

      <Grid style={{ marginBottom: 25 }} justify="space-between">
        <Grid.Col span={10}>
          <Title order={3}>Categorias</Title>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            size="sm"
            variant="outline"
            style={{ float: 'right' }}
            onClick={() => setOpenCreate(true)}
          >
            Criar categoria
          </Button>
        </Grid.Col>
      </Grid>

      <Table>
        <thead>
          <tr>
            {/* <th>Imagem</th> */}
            <th>Título</th>
            <th>Cor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(tableQueryResult.data.data || []).map((row) => (
            <tr key={`category-${row.uuid}`}>
              {/* <td>
                <img
                  src={row.image}
                  alt={row.title}
                  style={{ overflow: 'hidden', height: 36, maxWidth: 50 }}
                />
              </td> */}
              <td>{row.title}</td>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 6,
                      marginRight: 5,
                      boxShadow: '0 0 2px #333',
                      background: row.productsColor?.startsWith('#')
                        ? row.productsColor
                        : `#${row.productsColor}`
                    }}
                  />
                  {row.productsColor}
                </div>
              </td>
              <td style={{ width: 90 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button compact size="xs" variant="default" onClick={() => setEditId(row.uuid)}>
                    <Edit size={12} />
                  </Button>

                  <DeleteButton id={row.uuid} resource="categories" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Paper>
  )
}

export default CategoryList

import * as React from 'react'
import { Navbar, MediaQuery, ScrollArea } from '@mantine/core'
import { useRouterContext } from '@pankod/refine-core'

import SectionTabs from './SectionTabs'
import StoreSelect from './StoreSelect'
import NavbarItem from './NavbarItem'
import UserMenu from './UserMenu'
import useActivePill from '../services/useActivePill'

type AppNavbarProps = {
  hidden: boolean
}

const AppNavbar = ({ hidden }: AppNavbarProps) => {
  const { useLocation } = useRouterContext()
  const { pathname } = useLocation()
  const { item: submenu } = useActivePill(pathname)

  return (
    <Navbar width={{ sm: 200 }} p="xs" hiddenBreakpoint="sm" hidden={hidden}>
      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <div>
          <StoreSelect />
          <SectionTabs mt="sm" />
        </div>
      </MediaQuery>
      <Navbar.Section grow mt="xs">
        <ScrollArea style={{ height: '75vh' }}>
          {(submenu?.children || []).map((item) => (
            <NavbarItem
              key={item.label}
              color={item?.color || 'grape'}
              label={item.label}
              icon={item?.icon || null}
              link={item.link}
            />
          ))}
        </ScrollArea>
      </Navbar.Section>
      <Navbar.Section>
        <UserMenu />
      </Navbar.Section>
    </Navbar>
  )
}

export default AppNavbar

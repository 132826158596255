import * as React from 'react'
import { atom, selector } from 'recoil'
import { ShoppingCart, Table, Tags, Settings } from 'tabler-icons-react'

type IAvailableItem = {
  label: string
  link: string
  children: Array<{
    label: string
    link: string
    color?: string
    icon?: React.ReactNode
  }>
}

type IReportItem = {
  label: string
  link: string
  color?: string
  icon?: React.ReactNode
}

export const menuState = atom<null | number>({
  key: 'menu',
  default: 0
})

export const availableItemsState = atom<IAvailableItem[]>({
  key: 'available',
  default: [
    {
      label: 'Vendas',
      link: `${process.env.REACT_APP_SITE_BASE}order`,
      children: [
        {
          label: 'Pedidos',
          link: `${process.env.REACT_APP_SITE_BASE}order`,
          color: 'yellow',
          icon: <ShoppingCart size={16} />
        }
      ]
    },
    {
      label: 'Cadastros',
      link: `${process.env.REACT_APP_SITE_BASE}products`,
      children: [
        {
          label: 'Produtos',
          link: `${process.env.REACT_APP_SITE_BASE}products`,
          color: 'teal',
          icon: <Table size={16} />
        },
        {
          label: 'Categorias',
          link: `${process.env.REACT_APP_SITE_BASE}categories`,
          color: 'orange',
          icon: <Tags size={16} />
        },
        {
          label: 'Configurações',
          link: `${process.env.REACT_APP_SITE_BASE}configuration`,
          color: 'grape',
          icon: <Settings size={16} />
        }
      ]
    },
    {
      label: 'Relatórios',
      link: `${process.env.REACT_APP_SITE_BASE}reports`,
      children: []
    }
  ]
})

export const availableReports = atom<IReportItem[]>({
  key: 'reports',
  default: []
})

export const submenuSelector = selector({
  key: 'submenu',
  get: ({ get }) => {
    const menu = get(menuState)
    const available = get(availableItemsState)

    return available[menu || 0]
  }
})

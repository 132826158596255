import * as React from 'react'
import { Badge, Card, Grid, Text, Skeleton } from '@mantine/core'
import { User, PaperBag } from 'tabler-icons-react'
import type { Order } from '../interfaces'
import { formatNumber } from '../services/format'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')
dayjs.extend(relativeTime)

const allStatus: Record<string, { color: string; label: string }> = {
  CREATED: { color: 'yellow', label: 'Novo' },
  IN_PRODUCTION: { color: 'red', label: 'Em Produção' },
  READY: { color: 'violet', label: 'Pronto' },
  IN_DELIVERY: { color: 'blue', label: 'Em Entrega' },
  DELIVERED: { color: 'green', label: 'Concluído' }
}

type SellCardProps = {
  order?: Order
  selected?: boolean
  onClick?: () => void
}

const SellCard = ({ selected, order, onClick }: SellCardProps) => {
  if (order) {
    return (
      <Card
        withBorder={selected}
        shadow={selected ? 'lg' : 'sm'}
        p="lg"
        m="sm"
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <Grid justify="space-between">
          <Text size="xs" weight="bold">
            #{order.sequence}
          </Text>
          {allStatus[order.status] ? (
            <Badge color={allStatus[order.status].color}>{allStatus[order.status].label}</Badge>
          ) : (
            <Badge color="gray">{order.status}</Badge>
          )}
        </Grid>

        <Grid mt="md">
          <User size={22} style={{ marginRight: 3 }} />
          <Text weight="bold">{order.userKey}</Text>
        </Grid>

        <Grid mt="md" align="center">
          <PaperBag size={22} style={{ marginRight: 3 }} />
          <Text>{order.orderType === 'TAKE_AWAY' ? 'Retirada' : 'Delivery'}</Text>
        </Grid>

        <Grid mt="xl" align="flex-end" justify="space-between">
          <Text size="sm" color="gray">
            Recebido {dayjs(order.createdAt).format('HH:mm')}
          </Text>
          <Text weight="bold" size="lg">
            {formatNumber(order.amount / 100)}
          </Text>
        </Grid>
      </Card>
    )
  }

  return (
    <Card>
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} width="70%" radius="xl" />
    </Card>
  )
}

export default SellCard

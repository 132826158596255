import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

type IStore = {
  token: string
  store: {
    id: number
    title: string
    orderListType: string
  }
}

const { persistAtom } = recoilPersist({
  key: 'persist',
  storage: sessionStorage
})

export const activeStoreState = atom<null | number>({
  key: 'activeStore',
  default: 0,
  effects_UNSTABLE: [persistAtom]
})

export const allStoresState = atom<IStore[]>({
  key: 'allStores',
  default: []
})

export const activeStoreSelect = selector<IStore>({
  key: 'activeStoreSelector',
  get: ({ get }) => {
    const storeIndex = get(activeStoreState)
    const allStores = get(allStoresState)

    return allStores[storeIndex ?? 0]
  }
})

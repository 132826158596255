import * as React from 'react'
import { Text, Grid, Burger, Header, MediaQuery, useMantineTheme } from '@mantine/core'

import SectionTabs from './SectionTabs'
import StoreSelect from './StoreSelect'

type AppHeaderProps = {
  hidden: boolean
  setHidden: React.Dispatch<React.SetStateAction<boolean>>
}

const AppHeader = ({ hidden, setHidden }: AppHeaderProps) => {
  const theme = useMantineTheme()

  return (
    <Header height={60} p="xs">
      <Grid justify="space-between" align="center">
        <Grid.Col span={2}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                mr="xl"
                size="sm"
                opened={!hidden}
                color={theme.colors.gray[6]}
                onClick={() => setHidden((o) => !o)}
              />
            </MediaQuery>
            <img src="https://glowpos.com/img/thumbnail.png" />
            <div style={{ marginLeft: 16 }}>
              <Text
                size="xl"
                weight={700}
                variant="gradient"
                gradient={{ from: 'yellow', to: 'orange', deg: 45 }}
              >
                Admin
              </Text>
              <Text color="dimmed" style={{ marginTop: -3, fontSize: '60%' }}>
                versão {process.env.REACT_APP_VERSION}
              </Text>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col span={6}>
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <SectionTabs />
          </MediaQuery>
        </Grid.Col>
        <Grid.Col span={2}>
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <StoreSelect />
          </MediaQuery>
        </Grid.Col>
      </Grid>
    </Header>
  )
}

export default AppHeader

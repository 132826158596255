import * as React from 'react'
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core'
import { useNavigation } from '@pankod/refine-core'

interface NavbarItemProps {
  icon: React.ReactNode
  color: string
  label: string
  link: string
}

const NavbarItem = ({ label, color, icon, link }: NavbarItemProps) => {
  const { push } = useNavigation()

  return (
    <UnstyledButton
      onClick={() => push(link)}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.black,

        '&:hover': {
          backgroundColor: theme.colors.gray[0]
        }
      })}
    >
      <Group>
        {icon && (
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>
        )}
        <Text size="sm" weight={500}>
          {label}
        </Text>
      </Group>
    </UnstyledButton>
  )
}

export default NavbarItem

import axios from 'axios'
import { HttpError } from '@pankod/refine-core'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status
    }

    return Promise.reject(customError)
  }
)

axiosInstance.interceptors.request.use((config) => {
  const dirty = window.localStorage.getItem('glow-token')
  const token = JSON.parse(dirty || '""')
  config.headers = {
    ...config.headers,
    Authorization: token ? `Bearer ${token}` : 'Basic slkfjslkdfjl'
  }
  return config
})

export default axiosInstance

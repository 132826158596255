export const transform = (external: any) => {
  // const order = {
  //   sequence: external.sequence,
  //   status: external.status,
  //   deliveryTime: '12:33',
  //   createdAt: '11:10',
  //   customer: 'João da Silva (51 998877669)',
  //   address: 'Rua antonio carlos magalhães, 2345 - apto 804 - Centro - Porto Alegre',
  //   deliveryman: 'Zé das motos',
  //   observations: 'Sem cebola e com muita calabresa',
  //   tableItems: [
  //     { quantity: '1x', description: 'Massa Carbonara', value: 'R$ 100,00' },
  //     { quantity: '2x', description: 'Refri coca-cola lata', value: 'R$ 100,00' },
  //     { quantity: '', description: 'Taxa de Entrega', value: 'R$ 8,00' },
  //     { quantity: '', description: 'Total', value: 'R$ 208,00' }
  //   ]
  // }

  return external
}

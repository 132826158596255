import * as React from 'react'
import { Loader, Title, Table, Paper, Button, Grid } from '@mantine/core'
import { useRecoilValue } from 'recoil'
import { useTable, useMany } from '@pankod/refine-core'
import { Edit, CircleCheck, CircleX } from 'tabler-icons-react'

import DeleteButton from '../../components/DeleteButton'
import { activeStoreState } from '../../state/store'
import { formatNumber } from '../../services/format'

import CreateProduct from './Create'
import EditProduct from './Edit'

const normalizeThumb = (thumb: string) => {
  if (thumb.startsWith('http')) {
    return thumb
  }

  return `${process.env.REACT_APP_ASSET_BASE}/${thumb}`
}

const ProductList = () => {
  const [editId, setEditId] = React.useState<string | null>(null)
  const [openCreate, setOpenCreate] = React.useState<boolean>(false)

  const store = useRecoilValue(activeStoreState)
  const { tableQueryResult } = useTable({
    resource: 'products',
    metaData: { store }
  })

  let categoryIds: string[] = []
  let categories: Record<string, string> = {}

  if (tableQueryResult && tableQueryResult.data) {
    categoryIds = (tableQueryResult.data.data || []).map((it) => it.productCategoryUuid)
  }

  const catQuery = useMany({
    resource: 'categories',
    ids: categoryIds
  })

  if (catQuery && catQuery.data) {
    categories = (catQuery.data.data || []).reduce((agg, it) => {
      return { ...agg, [it.uuid]: it.title }
    }, {})
  }

  return (
    <Paper shadow="sm" p="md" withBorder>
      <CreateProduct
        title="Criar Produto"
        opened={openCreate}
        onClose={() => setOpenCreate(false)}
        categories={Object.keys(categories).map((k) => ({ value: k, label: categories[k] }))}
      />

      <EditProduct
        id={editId || 0}
        title="Edit Produto"
        opened={editId !== null}
        onClose={() => setEditId(null)}
        categories={Object.keys(categories).map((k) => ({ value: k, label: categories[k] }))}
      />

      <Grid style={{ marginBottom: 25 }} justify="space-between">
        <Grid.Col span={10}>
          <Title order={3}>Produtos</Title>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            size="sm"
            variant="outline"
            style={{ float: 'right' }}
            onClick={() => setOpenCreate(true)}
          >
            Criar produto
          </Button>
        </Grid.Col>
      </Grid>
      {tableQueryResult && tableQueryResult.isLoading && <Loader />}

      {tableQueryResult && tableQueryResult.data && (
        <Table>
          <thead>
            <tr>
              <th>Imagem</th>
              <th>Nome</th>
              <th>Código do Produto</th>
              <th>Preço</th>
              <th>Categoria</th>
              <th>Ativo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(tableQueryResult.data.data || []).map((row) => (
              <tr key={`product-${row.uuid}`}>
                <td>
                  {row.thumb && (
                    <img
                      src={normalizeThumb(row.thumb)}
                      alt={row.title?.substr(0, 10)}
                      style={{ overflow: 'hidden', height: 36, maxWidth: 50 }}
                    />
                  )}
                </td>
                <td>{row.title}</td>
                <td>{row.sku}</td>
                <td>{formatNumber(row.price / 100)}</td>
                <td>{categories[row.productCategoryUuid]}</td>
                <td>
                  {row.available ? (
                    <CircleCheck size={24} color="green" />
                  ) : (
                    <CircleX size={24} color="red" />
                  )}
                </td>
                <td style={{ width: 90 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button compact size="xs" variant="default" onClick={() => setEditId(row.uuid)}>
                      <Edit size={12} />
                    </Button>

                    <DeleteButton id={row.uuid} resource="products" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Paper>
  )
}

export default ProductList

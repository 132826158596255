import * as React from 'react'
import { Modal, TextInput, Button, Group, PasswordInput } from '@mantine/core'
import { useForm } from '@pankod/refine-core'
import { useForm as useValidateForm } from '@mantine/form'

type UserModalProps = {
  opened: boolean
  setOpened: (value: boolean) => void
}

const UserModal = ({ opened, setOpened }: UserModalProps) => {
  const { onFinish, formLoading } = useForm({
    action: 'edit',
    redirect: false,
    resource: 'users',
    onMutationSuccess: () => {
      setOpened(false)
    }
  })

  const form = useValidateForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
      confirm_password: '',
      id: '',
      accountId: ''
    },

    validate: {
      password: (value) => {
        if (!value) return null
        return value.length < 8 ? 'A senha deve possuir no minimo 8 caracteres' : null
      },
      confirm_password: (value, values) =>
        value !== values.password ? 'Senha deve ser a mesma' : null
    }
  })

  React.useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('glow-user') || '')
    form.setFieldValue('name', user.name)
    form.setFieldValue('email', user.email)
    form.setFieldValue('accountId', user.accountId)
    form.setFieldValue('id', user.id)
  }, [])

  return (
    <Modal opened={opened} onClose={() => setOpened(false)} title="Preferências do Usuário">
      <form
        style={{ paddingBottom: 20, width: '95%' }}
        onSubmit={form.onSubmit((values) => onFinish(values))}
      >
        <TextInput label="Nome" placeholder="Nome" required {...form.getInputProps('name')} />

        <TextInput
          mt="md"
          label="Email"
          placeholder="Email"
          required
          {...form.getInputProps('email')}
        />

        {/* <TextInput
          mt="md"
          label="Telefone Celular"
          placeholder="Telefone Celular"
          {...form.getInputProps('phone')}
        /> */}

        <PasswordInput
          mt="md"
          label="Senha"
          placeholder="Senha"
          {...form.getInputProps('password')}
        />

        <PasswordInput
          mt="md"
          label="Confirmar Senha"
          placeholder="Confirmar Senha"
          {...form.getInputProps('confirm_password')}
        />

        <Group position="right" mt={40}>
          <Button type="submit" loading={formLoading}>
            Atualizar usuário
          </Button>
        </Group>
      </form>
    </Modal>
  )
}

export default UserModal

import List from './List'
import Show from './Show'

const resource = {
  name: 'reports',
  list: List,
  show: Show
}

export default resource

import * as React from 'react'
import baseRouterProvider from '@pankod/refine-react-router-v6'

const CustomRouterComponent = () => (
  <baseRouterProvider.RouterComponent basename={process.env.REACT_APP_SITE_BASE} />
)

const routerProvider = {
  ...baseRouterProvider,
  RouterComponent: CustomRouterComponent,
  routes: []
}

export default routerProvider

import * as React from 'react'
import { OpenNotificationParams } from '@pankod/refine-core'
import { showNotification, hideNotification } from '@mantine/notifications'
import { AlertCircle, CircleCheck } from 'tabler-icons-react'

const notificationProvider = {
  open: (params: OpenNotificationParams) => {
    const colors = {
      success: 'green',
      error: 'red',
      progress: 'blue'
    }

    showNotification({
      id: params.key,
      title: params.message,
      message: params.description,
      color: colors[params.type],
      icon: params.type === 'error' ? <AlertCircle /> : <CircleCheck />
    })
  },
  close: (key: string) => {
    hideNotification(key)
  }
}

export default notificationProvider

import * as React from 'react'
import { RecoilRoot } from 'recoil'
import { MantineProvider } from '@mantine/styles'
import { NotificationsProvider } from '@mantine/notifications'
import { Refine } from '@pankod/refine-core'
import http from 'services/http'

import routerProvider from 'providers/routerProvider'
import dataProvider from 'providers/dataProvider'
import authProvider from 'providers/authProvider'
import notificationProvider from 'providers/notificationProvider'

import ErrorPage from 'components/ErrorPage'
import LoginPage from 'components/LoginPage'
import Layout from 'components/Layout'

import categoriesResource from 'resources/categories'
import productsResource from 'resources/products'
import reportsResource from 'resources/reports'
import sellsResource from 'resources/sells'
import configurationResource from 'resources/configuration'

function App() {
  return (
    <RecoilRoot>
      <MantineProvider
        withNormalizeCSS
        withGlobalStyles
        theme={{
          primaryColor: 'yellow'
        }}
      >
        <NotificationsProvider>
          <Refine
            authProvider={authProvider}
            routerProvider={routerProvider}
            notificationProvider={notificationProvider}
            dataProvider={dataProvider(process.env.REACT_APP_API as string, http)}
            catchAll={<ErrorPage />}
            Layout={Layout}
            LoginPage={LoginPage}
            resources={[
              sellsResource,
              categoriesResource,
              productsResource,
              reportsResource,
              configurationResource
            ]}
          />
        </NotificationsProvider>
      </MantineProvider>
    </RecoilRoot>
  )
}

export default App

import * as React from 'react'
import { useForm } from '@pankod/refine-core'
import { useForm as useValidateForm } from '@mantine/form'
import {
  Drawer,
  TextInput,
  Textarea,
  Group,
  Switch,
  Grid,
  Button,
  Box,
  Text,
  NumberInput,
  Select,
  SelectItem,
  ScrollArea
} from '@mantine/core'
import { Photo } from 'tabler-icons-react'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'

type EditProductProps = {
  id: string | number
  title: string
  opened: boolean
  onClose: () => void
  categories: SelectItem[]
}

const UploadArea = () => {
  return (
    <Group position="center">
      <Photo size={28} />
      <Text size="xs" align="center">
        Clique aqui para selecionar uma imagem
      </Text>
    </Group>
  )
}

const EditProduct = ({ id, title, opened, onClose, categories }: EditProductProps) => {
  const { setId, onFinish, queryResult, formLoading } = useForm({
    action: 'edit',
    redirect: false,
    resource: 'products',
    onMutationSuccess: () => {
      onClose()
    }
  })

  const form = useValidateForm({
    initialValues: {
      title: '',
      price: 0,
      productCategoryUuid: '',
      sku: '',
      barcode: '',
      thumb: '',
      observations: '',
      available: false
    },

    validate: {}
  })

  React.useEffect(() => {
    setId(id || undefined)
  }, [id])

  React.useEffect(() => {
    form.setValues({
      title: queryResult?.data?.data.title || '',
      price: queryResult?.data?.data.price / 100 || 0,
      productCategoryUuid: queryResult?.data?.data.productCategoryUuid || '',
      sku: queryResult?.data?.data.sku || '',
      barcode: queryResult?.data?.data.barcode || '',
      thumb: queryResult?.data?.data.thumb || '',
      observations: queryResult?.data?.data.observations || '',
      available: queryResult?.data?.data.available || ''
    })
  }, [id, queryResult?.data])

  return (
    <Drawer opened={opened} onClose={onClose} title={title} padding="md" size="xl" position="right">
      <Box sx={{}} mx="auto">
        <ScrollArea style={{ height: '90vh' }}>
          <form
            style={{ paddingBottom: 20, width: '95%' }}
            onSubmit={form.onSubmit((values) => {
              values.price = values.price * 100
              return onFinish(values)
            })}
          >
            <TextInput
              label="Nome"
              placeholder="Nome do produto"
              required
              {...form.getInputProps('title')}
            />

            <NumberInput
              mt="sm"
              label="Preço do Produto"
              placeholder="Preço"
              decimalSeparator=","
              required
              precision={2}
              step={0.5}
              {...form.getInputProps('price')}
            />

            <Select
              mt="sm"
              label="Categoria"
              required
              searchable
              nothingFound="Nenhuma opção encontrada"
              data={categories}
              {...form.getInputProps('productCategoryUuid')}
            />

            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  mt="sm"
                  label="Código do Produto"
                  placeholder="Código do Produto"
                  {...form.getInputProps('sku')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  mt="sm"
                  label="Código de Barras (EAN 13)"
                  placeholder="Código de Barras"
                  {...form.getInputProps('barcode')}
                />
              </Grid.Col>
            </Grid>

            <Textarea
              mt="sm"
              label="Descrição do Produto"
              placeholder="Descrição do Produto"
              {...form.getInputProps('observations')}
            />

            <Text size="sm" mb="xs" mt="lg">
              Imagem
            </Text>
            <Grid>
              <Grid.Col span={6}>
                <Dropzone
                  onDrop={(files) => console.log(files)}
                  accept={IMAGE_MIME_TYPE}
                  style={{ minHeight: 140, display: 'flex', alignItems: 'center' }}
                >
                  {() => <UploadArea />}
                </Dropzone>
              </Grid.Col>
              <Grid.Col span={6}>
                <Switch
                  mt="sm"
                  label="Ativo"
                  {...form.getInputProps('available', { type: 'checkbox' })}
                />
              </Grid.Col>
            </Grid>

            <Group position="right" mt="lg">
              <Button type="submit" loading={formLoading}>
                Salvar Produto
              </Button>
            </Group>
          </form>
        </ScrollArea>
      </Box>
    </Drawer>
  )
}

export default EditProduct

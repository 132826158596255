import { AuthProvider } from '@pankod/refine-core'
import http from 'services/http'

type AuthResponse = {
  stores: {
    store: Record<string, string | number | boolean>
    token: string
  }
  user: {
    name: string
    login: string
    role: string
  }
  token: string
}

const authProvider: AuthProvider = {
  login: async (params: Record<string, string>) => {
    const response = await http.post<AuthResponse>('/account/login/web', {
      login: params.username,
      password: params.password
    })

    if (response.status === 200) {
      localStorage.setItem('glow-stores', JSON.stringify(response.data.stores))
      localStorage.setItem(
        'glow-user',
        JSON.stringify({
          ...response.data.user,
          email: params.username
        })
      )
      localStorage.setItem('glow-token', JSON.stringify(response.data.token))

      return Promise.resolve()
    }

    return Promise.reject()
  },
  logout: () => {
    localStorage.removeItem('glow-stores')
    localStorage.removeItem('glow-user')
    localStorage.removeItem('glow-token')
    return Promise.resolve()
  },
  checkError: () => {
    // we can check here for the error
    return Promise.resolve()
  },
  checkAuth: () =>
    localStorage.getItem('glow-token') ? Promise.resolve() : Promise.reject('Usuário expirou'),
  getPermissions: async () => {
    const user = await JSON.parse(localStorage.getItem('glow-user') || '')
    return user?.role
  },
  getUserIdentity: () => JSON.parse(localStorage.getItem('glow-user') || '')
}

export default authProvider

import * as React from 'react'
import { useForm, useCustom } from '@pankod/refine-core'
import { useForm as useValidateForm } from '@mantine/form'
import { useRecoilValue } from 'recoil'
import {
  TextInput,
  NumberInput,
  Group,
  Title,
  Container,
  Paper,
  Button,
  Loader,
  Grid
} from '@mantine/core'
import { activeStoreState, allStoresState } from '../../state/store'

const EditConfiguration = () => {
  const store = useRecoilValue(activeStoreState)
  const storeList = useRecoilValue(allStoresState)
  const id = storeList[store || 0]?.store.id

  const { setId, onFinish, queryResult, formLoading } = useForm({
    action: 'edit',
    redirect: false,
    resource: 'store',
    metaData: { store }
  })

  React.useEffect(() => {
    setId(id || undefined)
  }, [id])

  React.useEffect(() => {
    form.setValues({
      fantasyName: queryResult?.data?.data.fantasyName || '',
      title: queryResult?.data?.data.title || '',
      cnpj: queryResult?.data?.data.cnpj || '',
      zip: queryResult?.data?.data.zip || '',
      street: queryResult?.data?.data.street || '',
      number: queryResult?.data?.data.number || '',
      complement: queryResult?.data?.data.complement || '',
      city: queryResult?.data?.data.city || '',
      uf: queryResult?.data?.data.uf || '',
      ibge: queryResult?.data?.data.ibge || ''
    })
  }, [id, queryResult?.data])

  const form = useValidateForm({
    initialValues: {
      fantasyName: '',
      title: '',
      cnpj: '',
      zip: '',
      street: '',
      number: '',
      complement: '',
      city: 'Porto Alegre',
      uf: '',
      ibge: ''
    },
    validate: {
      fantasyName: (value) => (value ? null : 'Campo Obrigatório'),
      title: (value) => (value ? null : 'Campo Obrigatório'),
      cnpj: (value) => (value ? null : 'Campo Obrigatório'),
      zip: (value) => (value.toString().length == 8 ? null : 'Campo Obrigatório'),
      street: (value) => (value ? null : 'Campo Obrigatório')
    }
  })

  const response = useCustom({
    url: `https://viacep.com.br/ws/${form.values.zip}/json/`,
    method: 'get',
    queryOptions: {
      enabled: !!form.values.zip && form.values.zip.toString().length == 8,
      onSuccess: (data) => {
        form.setFieldValue('street', data.data.logradouro)
        form.setFieldValue('city', data.data.localidade)
        form.setFieldValue('uf', data.data.uf)
        form.setFieldValue('ibge', data.data.ibge)
      }
    }
  })

  return (
    <Container size="sm" px="xs">
      <Paper shadow="sm" p="md" withBorder>
        <Title order={3}>Configurações da Loja</Title>
        <form
          style={{ marginTop: 40 }}
          onSubmit={form.onSubmit((values) =>
            onFinish({
              ...values,
              id: queryResult?.data?.data.id,
              accountId: queryResult?.data?.data.accountId
            })
          )}
        >
          <TextInput
            label="Razão Social"
            placeholder="Razão Social da Loja"
            required
            {...form.getInputProps('title')}
          />
          <TextInput
            mt="sm"
            label="Nome Fantasia"
            placeholder="Nome Fantasia da Loja"
            required
            {...form.getInputProps('fantasyName')}
          />
          <TextInput
            mt="sm"
            label="CNPJ"
            placeholder="CNPJ"
            required
            {...form.getInputProps('cnpj')}
          />
          <Grid justify="space-between">
            <Grid.Col span={4}>
              <TextInput
                mt="sm"
                label="CEP"
                placeholder="CEP"
                required
                step={0}
                rightSection={response.status === 'loading' && <Loader size="xs" />}
                {...form.getInputProps('zip')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                mt="sm"
                label="Número"
                placeholder="Número"
                required
                {...form.getInputProps('number')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                mt="sm"
                label="Complemento"
                placeholder="Complemento"
                {...form.getInputProps('complement')}
              />
            </Grid.Col>
          </Grid>
          <Grid justify="space-between">
            <Grid.Col span={7}>
              <TextInput
                mt="sm"
                label="Endereço"
                placeholder="Endereço"
                {...form.getInputProps('street')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput mt="sm" label="Cidade" disabled {...form.getInputProps('city')} />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput mt="sm" label="Estado" disabled {...form.getInputProps('uf')} />
            </Grid.Col>
          </Grid>

          <Group position="right" mt={30}>
            <Button type="submit" loading={formLoading}>
              Salvar Loja
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  )
}

export default EditConfiguration

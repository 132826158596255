import * as React from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useList } from '@pankod/refine-core'
import { availableReports } from '../../state/menu'
import { activeStoreState } from '../../state/store'

const ReportsList = () => {
  const setReports = useSetRecoilState(availableReports)
  const store = useRecoilValue(activeStoreState)
  const { data } = useList({
    resource: 'reports',
    metaData: { store }
  })

  React.useEffect(() => {
    if (data) {
      setReports(
        data.data.map((item) => ({
          link: `/reports/show/${item.code}`,
          label: item.title,
          color: 'violet'
        }))
      )
    }
  }, [data])

  return <p>Lista de relatorios</p>
}

export default ReportsList

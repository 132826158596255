import * as React from 'react'
import { createStyles, Paper, TextInput, PasswordInput, Button, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLogin } from '@pankod/refine-core'

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundSize: 'cover',
    backgroundImage:
      'url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)'
  },

  form: {
    borderRight: `1px solid ${theme.colors.gray[3]}`,
    height: '100vh',
    maxWidth: 450,
    paddingTop: 80
  },

  title: {
    color: theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`
  },

  logo: {
    color: theme.black,
    width: 120,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

const LoginPage = () => {
  const { classes } = useStyles()
  const { mutate: login } = useLogin()
  const form = useForm({
    initialValues: {
      username: '',
      password: ''
    },
    validate: {
      username: (value) => (value ? null : 'Campo obrigatório'),
      password: (value) => (value ? null : 'Campo obrigatório')
    }
  })

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} align="center" mt="md" mb={50}>
          Bem vindo à Glow!
        </Title>

        <form onSubmit={form.onSubmit((values) => login(values))}>
          <TextInput
            label="Usuário"
            placeholder="digite o seu usuario"
            size="md"
            {...form.getInputProps('username')}
          />
          <PasswordInput
            label="Senha"
            placeholder="digite a sua senha"
            mt="md"
            size="md"
            {...form.getInputProps('password')}
          />

          <Button fullWidth mt="xl" size="md" type="submit">
            Entrar
          </Button>
        </form>
        <small style={{ position: 'absolute', bottom: 5, left: 5, fontSize: '70%' }}>
          versão {process.env.REACT_APP_VERSION}
        </small>
      </Paper>
    </div>
  )
}

export default LoginPage

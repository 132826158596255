import { AxiosInstance } from 'axios'
import { stringify } from 'query-string'
import { DataProvider, CrudOperators, CrudFilters, CrudSorting } from '@pankod/refine-core'

const mapResources = (resource: string, method: string): string => {
  switch (resource) {
    case 'categories':
      return 'account/store/catalog/productcategory'
    case 'products':
      return 'account/store/catalog/product'
    case 'store':
      return 'account/store'
    case 'users':
      return 'account/user'
    case 'order':
      if (method === 'getList') {
        return 'order/summary'
      }
      return 'order'
    case 'reports':
      return 'operation/report'
    default:
      return resource
  }
}

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'ne':
    case 'gte':
    case 'lte':
      return `_${operator}`
    case 'contains':
      return '_like'
    case 'eq':
    default:
      return ''
  }
}

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = []
    const _order: string[] = []

    sort.map((item) => {
      _sort.push(item.field)
      _order.push(item.order)
    })

    return {
      _sort,
      _order
    }
  }

  return
}

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {}
  if (filters) {
    filters.map((filter) => {
      if (filter.operator !== 'or') {
        const { field, operator, value } = filter

        if (field === 'q') {
          queryFilters[field] = value
          return
        }

        const mappedOperator = mapOperator(operator)
        queryFilters[`${field}${mappedOperator}`] = value
      }
    })
  }

  return queryFilters
}

const JsonServer = (apiUrl: string, httpClient: AxiosInstance): DataProvider => ({
  getList: async ({ resource, filters }) => {
    const url = `${apiUrl}/${mapResources(resource, 'getList')}`

    const queryFilters = generateFilter(filters)

    const { data, headers } = await httpClient.get(`${url}?${stringify(queryFilters)}`)

    const total = +headers['x-total-count']

    return {
      data,
      total
    }
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${mapResources(resource, 'getMany')}?${stringify({ id: ids })}`
    )

    return {
      data
    }
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${mapResources(resource, 'create')}`
    const { data } = await httpClient.post(url, variables)

    return {
      data
    }
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(
          `${apiUrl}/${mapResources(resource, 'createMany')}`,
          param
        )
        return data
      })
    )

    return { data: response }
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${mapResources(resource, 'update')}`

    const { data } = await httpClient.post(url, { ...variables, uuid: id })

    return {
      data
    }
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.post(
          `${apiUrl}/${mapResources(resource, 'updateMany')}`,
          {
            ...variables,
            uuid: id
          }
        )
        return data
      })
    )

    return { data: response }
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${mapResources(resource, 'getOne')}/${id}`

    const { data } = await httpClient.get(url)

    return {
      data
    }
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${mapResources(resource, 'deleteOne')}/${id}`

    const { data } = await httpClient.delete(url)

    return {
      data
    }
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(
          `${apiUrl}/${mapResources(resource, 'deleteMany')}/${id}`
        )
        return data
      })
    )
    return { data: response }
  },

  getApiUrl: () => {
    return apiUrl
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`

    if (sort) {
      const generatedSort = generateSort(sort)
      if (generatedSort) {
        const { _sort, _order } = generatedSort
        const sortQuery = {
          _sort: _sort.join(','),
          _order: _order.join(',')
        }
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters)
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers
      }
    }

    let axiosResponse
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload)
        break
      case 'delete':
        axiosResponse = await httpClient.delete(url)
        break
      default:
        axiosResponse = await httpClient.get(requestUrl)
        break
    }

    const { data } = axiosResponse

    return Promise.resolve({ data })
  }
})

export default JsonServer

import * as React from 'react'
import { Menu, UnstyledButton, Group, Text } from '@mantine/core'
import { ChevronRight, Logout, Settings } from 'tabler-icons-react'
import { useLogout, useGetIdentity } from '@pankod/refine-core'
import UserModal from './UserModal'

const UserButton = React.forwardRef<HTMLButtonElement>(function UserButtonComponent(props, ref) {
  const { data: identity } = useGetIdentity()
  return (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        color: theme.black,

        '&:hover': {
          backgroundColor: theme.colors.gray[0]
        }
      })}
      {...props}
    >
      <Group>
        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {identity?.name}
          </Text>
          <Text
            size="xs"
            color="dimmed"
            style={{ width: 125, overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {identity?.role}
          </Text>
        </div>

        <ChevronRight size={14} />
      </Group>
    </UnstyledButton>
  )
})

const UserMenu = () => {
  const { mutate: logout } = useLogout()
  const [opened, setOpened] = React.useState(false)
  return (
    <>
      <UserModal opened={opened} setOpened={setOpened} />
      <Menu position="right" control={<UserButton />}>
        <Menu.Label>Aplicação</Menu.Label>
        <Menu.Item icon={<Settings size={14} />} onClick={() => setOpened(true)}>
          Preferências
        </Menu.Item>
        <Menu.Item icon={<Logout size={14} />} onClick={() => logout()}>
          Sair
        </Menu.Item>
      </Menu>
    </>
  )
}

export default UserMenu

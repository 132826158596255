import * as React from 'react'
import { AppShell, useMantineTheme } from '@mantine/core'

import Navbar from './Navbar'
import Header from './Header'

const Layout: React.FC = ({ children }) => {
  const theme = useMantineTheme()
  const [hidden, setHidden] = React.useState<boolean>(true)

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      padding="md"
      navbar={<Navbar hidden={hidden} />}
      header={<Header hidden={hidden} setHidden={setHidden} />}
      styles={{
        main: { background: theme.colors.gray[0] }
      }}
    >
      {children}
    </AppShell>
  )
}

export default Layout

import * as React from 'react'
import { Paper, Box, Center, Button, Table, Title, Text, Badge, Grid, Divider } from '@mantine/core'
import { Artboard, X } from 'tabler-icons-react'
import { useApiUrl, useOne } from '@pankod/refine-core'
import { transform } from './dto'

type ShowSellProps = {
  id?: string
  onClose: () => void
}

const allStatus: Record<string, { color: string; label: string; nextAction: string }> = {
  CREATED: { color: 'yellow', label: 'Novo', nextAction: 'Iniciar Produção' },
  IN_PRODUCTION: { color: 'red', label: 'Em Produção', nextAction: 'Produção Finalizada' },
  READY: { color: 'violet', label: 'Pronto', nextAction: 'Iniciar Entrega' },
  IN_DELIVERY: { color: 'blue', label: 'Em Entrega', nextAction: 'Concluir Pedido' },
  DELIVERED: { color: 'green', label: 'Concluído', nextAction: '' }
}

const putState = (id: string, state: string, apiUrl: string) => null

const ShowSell = ({ id, onClose }: ShowSellProps) => {
  const apiUrl = useApiUrl()

  const orderDetail = useOne({
    resource: 'order',
    id: id || '',
    queryOptions: {
      enabled: !!id
    }
  })

  const order = transform(orderDetail.data?.data || {})

  if (!id) {
    return (
      <Center style={{ height: '60vh', textAlign: 'center' }}>
        <div>
          <Artboard size={94} />
          <p>Selecione um pedido na lista ao lado.</p>
        </div>
      </Center>
    )
  }

  return (
    <Box
      sx={(theme) => ({
        background: theme.colors.gray[0],
        marginLeft: '-16px',
        padding: '24px 32px',
        height: '100%'
      })}
    >
      <Grid>
        <div style={{ marginRight: 15 }}>
          <Title>Pedido #{order.sequence}</Title>
          <Text color="gray" size="xl">
            Delivery {order.deliveryTime}
          </Text>
        </div>

        {allStatus[order.status] ? (
          <Badge size="xl" color={allStatus[order.status].color}>
            {allStatus[order.status].label}
          </Badge>
        ) : (
          <Badge size="xl" color="gray">
            {order.status}
          </Badge>
        )}

        <div style={{ flex: 1 }} />
        <Button variant="subtle" color="dark" onClick={onClose}>
          <X size={24} />
        </Button>
      </Grid>
      <Divider sx={{ margin: '30px 0 22px' }} variant="dashed" />

      <Text size="md">
        <span style={{ paddingRight: '38px', fontWeight: 'bold' }}>Cliente:</span>
        {order.customer}
      </Text>
      <Text size="md" sx={{ marginTop: 12 }}>
        <span style={{ paddingRight: '20px', fontWeight: 'bold' }}>Endereço:</span>
        {order.address}
      </Text>
      <Text size="md" sx={{ marginTop: 12 }}>
        <span style={{ paddingRight: '20px', fontWeight: 'bold' }}>Entregador:</span>
        {order.deliveryman}
      </Text>

      <Divider sx={{ margin: '30px 0 22px' }} variant="dashed" />

      <Paper shadow="xs" p="md" mb="lg">
        <Title order={4}>Itens do Pedido:</Title>
        <Table fontSize="lg" sx={{ width: '100%' }}>
          <tbody>
            {order.tableItems.map((item: any, idx: number) => (
              <tr key={`table-items-${idx}`}>
                <td style={{ width: 30 }}>{item.quantity}</td>
                <td>{item.description}</td>
                <td style={{ width: 120, textAlign: 'right' }}>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Title order={4} mt="md">
          Observações
        </Title>
        <Text>{order.observations}</Text>
      </Paper>

      <Grid justify="flex-end">
        {allStatus[order.status]?.nextAction && (
          <Button
            size="xl"
            color={allStatus[order.status].color}
            onClick={() => putState(id, 'state', apiUrl)}
          >
            {allStatus[order.status].nextAction}
          </Button>
        )}
      </Grid>
    </Box>
  )
}

export default ShowSell

import { useRecoilValue } from 'recoil'
import { availableItemsState, availableReports } from '../state/menu'

const useActivePill = (pathname: string) => {
  const available = useRecoilValue(availableItemsState)
  const reports = useRecoilValue(availableReports)

  const listIndex = available.findIndex((item) => {
    const linkList = [item.link, ...item.children.map((c) => c.link)]
    return linkList.reduce((found, link) => {
      return found || pathname.startsWith(link)
    }, false)
  })

  const index = Math.max(0, listIndex)
  const item = available[index]

  if (index == 2) {
    return {
      index,
      item: { children: reports }
    }
  }

  return { index, item }
}

export default useActivePill
